import React, { useEffect, useState } from "react";
import styles from '@/styles/Navbar.module.css';
import Link from 'next/link';
import { useRouter } from 'next/router';
import HeaderTextContext from "@/contexts/HeaderTextContext";

function Navbar({ hostname }) {
  const router = useRouter();
  const headerText = React.useContext(HeaderTextContext);
  //console.log(" in nav", headerText);
  // Function to determine if a link is the active page
  const isActive = (path) => {
    const currentPath = router.pathname;

    // Check if the current path starts with the provided path
    if (currentPath === path) {
      return 'active';
    }
    return '';
  };
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const url = process.env.NEXT_PUBLIC_URL;
  const url2 = process.env.NEXT_PUBLIC_URL_2;

  function opennav() {
    var element = document.getElementById("navigation");
    var element2 = document.getElementById("nav-toggle");
    element.classList.toggle("nav-open");
    element2.classList.toggle("nav-open");
  }

  return (
    <>
      <div className={`${styles.navwrap} ${scrolled ? "is-sticky" : ""}`}>
        {hostname !== 'halo' &&
          <div className="bannerText">
            <p dangerouslySetInnerHTML={{ __html: headerText }}></p>
          </div>
        }
        <div className="navbarwrapper">
          <Link
            className="navtoggle"
            id="nav-toggle"
            href="javascript:;"
            onClick={() => opennav()}
          >
            <span className=""></span>
            <span></span>
            <span></span>
          </Link>
          <nav className="navbar" id="navigation">
            {hostname === 'halo' ?
              (
                <div id="logo" className="">
                  <Link href="/home">
                    <img className="logo-nav logo-nav-left" src="/app/Assets/Halo/Halo-logo.svg"></img>
                  </Link>
                </div>
              ) : (
                <>
                  <div className="">
                    <Link href="/home">
                      <img className="logo-nav" src="/app/Assets/adaptive-name.svg"></img>
                    </Link>
                  </div>
                  <ul className="navbarmenu">
                    <li><Link className={isActive('/home')} href="/home" onClick={() => { opennav(); }}>Home</Link></li>
                    <li><Link className={isActive('/what_we_do/WhatWeDo')} href="/what_we_do/WhatWeDo" onClick={() => { opennav(); }}>What We Do</Link></li>
                    <li>
                      <Link className={isActive('/what_we_serve')} href="javascript:;">Who We Serve</Link>
                      <ul className="submenu">
                        <li><Link className={isActive('/what_we_serve/AdvisorsWealthManagers')} onClick={() => { opennav(); }} href="/what_we_serve/AdvisorsWealthManagers">Advisors & Wealth Managers</Link></li>
                        <li><Link className={isActive('/what_we_serve/RetailInvestors')} onClick={() => { opennav(); }} href="/what_we_serve/RetailInvestors">Retail Investors</Link></li>
                        <li><Link className={isActive('/what_we_serve/Enterprises')} onClick={() => { opennav(); }} href="/what_we_serve/Enterprises">Enterprises</Link></li>
                      </ul>
                    </li>
                    {/* <li>
                      <Link className={isActive('/Product')} href="javascript:;">Products</Link>
                      <ul className="submenu">
                        <li><Link className={isActive('/Product/AdaptiveShield')} onClick={() => { opennav(); }} href="/Product/AdaptiveShield">Adaptive Shield</Link></li>
                        <li><Link className={isActive('/Product/AdaptiveIncome')} onClick={() => { opennav(); }} href="/Product/AdaptiveIncome">Adaptive Income <span className="commingsoon">Coming soon</span></Link></li>
                        <li><Link className={isActive('/Product/BlackSwan')} onClick={() => { opennav(); }} href="/Product/BlackSwan">Black Swan <span className="commingsoon">Coming soon</span></Link></li>
                        <li><Link className={isActive('/Product/CryptoShield')} onClick={() => { opennav(); }} href="/Product/CryptoShield">Crypto Shield <span className="commingsoon">Coming soon</span></Link></li>
                      </ul>
                    </li> */}
                    <li>
                      <Link className={isActive('/tools')} href="javascript:;">Tools</Link>
                      <ul className="submenu">
                        <li><Link className={isActive('/tools/riskcontribution')} onClick={() => { opennav(); }} href="/tools/riskcontribution">Risk Contribution</Link></li>
                        <li><Link className={isActive('/tools/factor_analysis')} onClick={() => { opennav(); }} href="/tools/factor_analysis">Factor Analysis</Link></li>
                        {/* <li><Link className={isActive('/tools/mc')} onClick={() => { opennav(); }} href="/tools/mc">Forward Test</Link></li> */}
                        <li><Link className={isActive('/tools/rw')} onClick={() => { opennav(); }} href="/tools/rw">Risk Weather</Link></li>
                        <li><Link className={isActive('/tools/ppc')} onClick={() => { opennav(); }} href="/tools/ppc">Protection Calculator</Link></li>
                      </ul>
                    </li> 
                    {/* <li><Link className={isActive('/tools/enterprise_api')} href="/tools/enterprise_api" onClick={() => { opennav(); }}>Enterprise API</Link></li> */}
                    <li><Link className={isActive('/Pricing')} href="/Pricing" onClick={() => { opennav(); }}>Pricing</Link></li>
                    <li><Link className={isActive(process.env.NEXT_PUBLIC_WEBSITE_URL + 'webinars/')} onClick={() => { opennav(); }} href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}webinars/`}>Webinars</Link></li>
                    <li>
                      <Link className={isActive('/About')} href="javascript:;">Company</Link>
                      <ul className="submenu">
                        <li><Link className={isActive('/About/AboutAdaptive')} onClick={() => { opennav(); }} href="/About/AboutAdaptive">About Adaptive</Link></li>
                        <li><Link className={isActive(process.env.NEXT_PUBLIC_WEBSITE_URL + 'faq/')} onClick={() => { opennav(); }} href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}faq/`}>Resources</Link></li>
                        <li><Link className={isActive(process.env.NEXT_PUBLIC_WEBSITE_URL + 'thought-leadership/')} onClick={() => { opennav(); }} href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}thought-leadership/`}>Blog</Link></li>
                      </ul>
                    </li>
                    <li>
                      <Link className="button" href={`${url2}`}>Launch App</Link>
                    </li>
                    <li className="navbutton">
                      {/* <Link
                        href="/demo/requestdemo"
                        rel="noopener noreferrer"
                        className="button secondary"
                        onClick={() => { opennav(); }}
                      >
                        Request a Demo
                      </Link> */}
                        <Link className="button secondary" href="/Pricing">Sign Up</Link>
                    </li>
                  </ul>
                </>
              )}
          </nav>
        </div>
      </div>
    </>
  );
}
export default Navbar;
